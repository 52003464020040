import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { Environment, ENVIRONMENT, NetworkController, NetworkSyncV2, NetworkV2 } from '@netfoundry-ui/shared/model';
import { FeatureService, HTTP_CLIENT, LoggerService, RefresherService } from '@netfoundry-ui/shared/services';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';

export const NETWORK_SERVICE = new InjectionToken<HttpClient>('NETWORK_SERVICE');

@Injectable({ providedIn: 'root' })
export class NetworkServiceV2 extends HateoasResourceOperation<NetworkV2> {
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        }
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    _networks = new BehaviorSubject<NetworkV2[]>([]);
    networkRefresher: any;

    networkLimitSource = new ReplaySubject<any>();
    networkLimit = this.networkLimitSource.asObservable();
    theNetworkLimitIs: any = {};
    apiUrl = '';

    constructor(
        private logger: LoggerService,
        private auth: AuthorizationService,
        private featureService: FeatureService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment,
        private refresher: RefresherService
    ) {
        super(NetworkV2);
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl : environment.v2apiUrl;
        this.initService();
    }

    override getResource(): Observable<NetworkV2> {
        throw new Error('Do not use: see get getNetwork');
    }

    override getPage(): Observable<PagedResourceCollection<NetworkV2>> {
        throw new Error('Do not use: see getNetworks');
    }

    getNetwork(id: string, options: GetOption = {}): Promise<NetworkV2 | undefined> {
        return super
            .getResource(id, { ...NetworkServiceV2.defaultHttpAccept, ...options })
            .toPromise()
            .then((network) => network);
    }

    public downloadNetwork(id: string, format = 'text/csv', embedAll = false, embed = ''): Observable<any> {
        const headers = new HttpHeaders().set('Accept', format);
        const params = new HttpParams();
        const embedStr = embedAll ? '?embed=all' : '?embed=' + embed;
        return this.http.get(this.apiUrl + 'networks/' + id + embedStr, {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public getsdsConfigPortEnabled(id: string): Observable<any> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(this.apiUrl + 'network-controllers/' + id + '?embed=host,softwareDeploymentState', {
            headers: headers,
        });
    }

    public toggleConfigPort(id: string, enableSoftwareConfigPort: boolean): Observable<any> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.patch(
            this.apiUrl + 'software-deployment-states/' + id,
            { softwareConfigurationPortEnabled: enableSoftwareConfigPort },
            {
                headers: headers,
            }
        );
    }

    async getNetworksPage(options?: PagedGetOption): Promise<NetworkV2[]> {
        if (options || this._networks.getValue().length === 0) {
            return super
                .getPage({ ...NetworkServiceV2.defaultHttpAccept, ...options })
                .pipe(
                    tap((val) => {
                        this.lastPageCount = val.totalPages;
                        this.lastTotalCount = val.totalElements;
                    })
                )
                .toPromise()
                .then((network) => network?.resources || ([] as NetworkV2[]));
        }
        return Promise.resolve(this._networks.getValue());
    }

    async getNetworks(options?: PagedGetOption): Promise<NetworkV2[]> {
        const headers = new HttpHeaders().set('Accept', 'application/hal+json');
        let url = `${this.apiUrl}networks`;
        if (this.featureService.isCloudZiti) {
            url = url + '?embed=network-controllers';
        }
        const pageParams = options?.pageParams || {};
        const params = options?.params || {};
        return this.http
            .get(url, {
                headers: headers,
                params: { ...pageParams, ...options?.params },
            })
            .toPromise()
            .then((result: any) => {
                this.lastPageCount = result.totalPages;
                this.lastTotalCount = result.totalElements;
                const networks: any = (result?._embedded?.networkList as NetworkV2[]) || [];
                return networks;
            });
    }

    findByNetworkGroupId(networkGroupId: string): Observable<PagedResourceCollection<NetworkV2>> {
        return super.getPage({ ...NetworkServiceV2.defaultHttpAccept, params: { networkGroupId } });
    }

    syncNetworks(network: NetworkV2, networkSyncList: NetworkSyncV2[]) {
        this.http.post(network['_links']['networks'].href + '/sync', networkSyncList).subscribe((resp) => {
            this.logger.info('Network sync response ', resp);
        });
    }

    createNetwork(networkConfig: any, format = 'application/json'): Observable<any> {
        const headers = new HttpHeaders().set('ContentType', format);
        return this.http.post(this.apiUrl + 'networks', networkConfig, {
            headers: headers,
            params: {},
            responseType: 'text',
        });
    }

    updateNetwork(networkConfig: any, format = 'application/json'): Observable<any> {
        const headers = new HttpHeaders().set('ContentType', format);
        return this.http.put(this.apiUrl + 'networks', networkConfig, {
            headers: headers,
            params: {},
            responseType: 'text',
        });
    }

    createResources(networkId: string, resourceData: any, format = 'application/json') {
        const headers = new HttpHeaders().set('ContentType', format);
        return this.http.put(this.apiUrl + 'networks/' + networkId, resourceData, {
            headers: headers,
            params: {},
            responseType: 'text',
        });
    }

    public renameNetwork(id: string, newName: string): Observable<any> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.patch(
            this.apiUrl + 'networks/' + id,
            { name: newName },
            {
                headers: headers,
            }
        );
    }

    public addPublicCertificate(id: string, enablePublicCertificate: boolean): Observable<any> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.patch(
            this.apiUrl + 'networks/' + id,
            { publicCertEnabled: enablePublicCertificate },
            {
                headers: headers,
            }
        );
    }

    public getNetworkControllerSecrets(id: string): Observable<any> {
        return this.http.get(this.apiUrl + 'network-controllers/' + id + '/secrets');
    }

    public getNetworkControllerSession(id: string): Observable<any> {
        return this.http.get(this.apiUrl + 'network-controllers/' + id + '/session');
    }

    public getZitiSessionToken(id: string): Observable<any> {
        const body = {
            type: 'session',
        };
        return this.http.post(this.apiUrl + 'networks/' + id + '/exchange', body);
    }

    public getZitiBearerToken(id: string): Observable<any> {
        const body = {
            type: 'bearer',
        };
        return this.http.post(this.apiUrl + 'networks/' + id + '/exchange', body);
    }

    public getNetworkControllerInfo(id: string): Observable<any> {
        return this.http.get(this.apiUrl + 'network-controllers/' + id + '?embed=all');
    }

    public restart(networkId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}networks/${networkId}/network-controller.host/restart`;
        return this.http.post(url, options) as Observable<any>;
    }

    public restartProcess(networkId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}networks/${networkId}/network-controller.process/restart`;
        return this.http.post(url, options) as Observable<any>;
    }

    public resize(networkId: string, size: string): Observable<string> {
        const body: any = { size };
        const url = `${this.apiUrl}networks/${networkId}/network-controller.host/resize`;
        return this.http.post(url, body) as Observable<any>;
    }

    public move(id: string, provider: string, region: string, ipAddress: string): Observable<string> {
        const options: any = {};
        const body: any = { provider, region, ipAddress };
        const url = `${this.apiUrl}network-controllers/${id}/move`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public createBackup(id: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}network-controllers/${id}/backups`;
        return this.http.post(url, options) as Observable<any>;
    }

    public validateUpdate(networkId: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.apiUrl + 'networks/' + networkId, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.apiUrl + 'networks', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public listBackups(id: string): Observable<any> {
        const options: any = {};
        const url = `${this.apiUrl}network-controllers/${id}/backups`;
        return this.http.get(url, options) as Observable<any>;
    }

    public restoreBackup(id: string, backupFile: string): Observable<string> {
        const options: any = {};
        const body: any = { backupFile };
        const url = `${this.apiUrl}network-controllers/${id}/restore`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public downloadToCsv(): Observable<any> {
        const params = new HttpParams().set('size', this.lastTotalCount.toString());
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.apiUrl + 'networks', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public downloadFileFormat(networkId: string, format = 'text/csv'): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount.toString());
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.apiUrl + 'networks', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public getNetworkLimits(networkId: string) {
        const params = new HttpParams().set('networkId', networkId).set('page', 0).set('size', 2000);
        const headers = new HttpHeaders().set('Accept', 'application/hal+json');
        return this.http.get(this.apiUrl + 'network-limits', {
            headers: headers,
            params: params,
        });
    }

    public suspend(networkId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}networks/${networkId}/suspend`;
        return this.http.post(url, options) as Observable<any>;
    }

    public resume(networkId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}networks/${networkId}/resume`;
        return this.http.post(url, options) as Observable<any>;
    }

    public getEmbeddedHostInfo(ids: string[]): Observable<any> {
        const params = new HttpParams().set('id', ids.join(',')).set('embed', 'host');
        return this.http.get(this.apiUrl + 'network-controllers', {
            params: params,
            responseType: 'json',
        });
    }

    async refreshNetworks() {
        if (this.networkRefresher) clearTimeout(this.networkRefresher);
        if (this.auth.canListNetworks()) {
            let params = {};
            if (this.featureService.isCloudZiti) {
              params = {
                embed: 'all'
              };
            }
            super
                .getPage({
                    headers: {
                        accept: 'application/hal+json',
                    },
                    pageParams: {
                        page: 0,
                        size: 2000,
                    },
                    params
                })
                .pipe(
                    tap((val) => {
                        this.lastPageCount = val.totalPages;
                        this.lastTotalCount = val.totalElements;
                    })
                )
                .subscribe((networks) => {
                    this._networks.next(networks.resources);
                });
        }

        this.networkRefresher = setTimeout(() => {
            this.refreshNetworks();
        }, this.refresher.refreshInterval);
    }

    setCurrentNetworkLimit(networkLimit: any) {
        this.networkLimitSource.next(networkLimit);
        this.theNetworkLimitIs = networkLimit;
    }

    public getSingleEmbeddedHost(id: string): Observable<any>  {
        const params = new HttpParams().set('embed', 'host');
        return this.http.get(this.apiUrl + 'network-controllers?id=' + id, {
            params: params,
            responseType: 'json',
        });
    }

    private getNetworkCSV(id: string, format = 'text/csv', embedAll = false, embed = ''): Observable<any> {
        const headers = new HttpHeaders().set('Accept', format);
        const params = new HttpParams();
        const embedStr = embedAll ? '?embed=all' : '?embed=' + embed;
        return this.http.get(this.apiUrl + 'networks/' + id + embedStr, {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    private async initService() {
        await this.refreshNetworks();
    }
}
