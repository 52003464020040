import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NetworkController, ENVIRONMENT, Environment } from '@netfoundry-ui/shared/model';
import { Observable } from 'rxjs';
import { HTTP_CLIENT } from '@netfoundry-ui/shared/services';
import {
    GetOption,
    HateoasResourceOperation,
    PagedGetOption,
    HttpMethod,
    PagedResourceCollection,
} from '@lagoshny/ngx-hateoas-client';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NetworkControllerService extends HateoasResourceOperation<NetworkController> {
    static defaultPaginationSize = 500;
    static defaultHttpAccept = {
        headers: { accept: 'application/hal+json' },
        pageParams: {
            page: 0,
            size: 2000,
        },
    };
    lastPageCount = 0;
    lastTotalCount = 0;
    apiUrl: string;

    constructor(@Inject(HTTP_CLIENT) private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {
        super(NetworkController);
        this.apiUrl = environment.v3Enabled ? environment.v3apiUrl: environment.v2apiUrl
    }

    getNetworkController(id: string | number, options: GetOption = {}): Promise<NetworkController | undefined> {
        return super
            .getResource(id, { ...NetworkControllerService.defaultHttpAccept, ...options })
            .toPromise()
            .then((controller) => controller);
    }

    async getNetworkControllerPage(options?: PagedGetOption, includeProperties?: string): Promise<NetworkController[]> {
        let params = { ...NetworkControllerService.defaultHttpAccept, ...options };
        if (includeProperties) {
            params = { ...params, headers: { accept: `application/hal+json;includeProperties=${includeProperties}` } };
        }
        return super
            .getPage(params)
            .pipe(
                tap((val) => {
                    this.lastPageCount = val.totalPages;
                    this.lastTotalCount = val.totalElements;
                })
            )
            .toPromise()
            .then((controllers) => (controllers ? controllers.resources : ([] as NetworkController[])));
    }

    public getHostSizes(provider: string): Observable<any> {
        const options: any = {};
        const url = `${this.apiUrl}host-sizes?type=NC&provider=${provider}`;

        return this.http.get(url, options) as Observable<any>;
    }

    public getAllocatedIps(networkId: string): Observable<any> {
        const options: any = {
            params: { networkId: networkId },
        };
        const url = `${this.apiUrl}host-addresses`;

        return this.http.get(url, options) as Observable<any>;
    }

    public restart(controllerId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}network-controllers/${controllerId}/host/restart`;
        return this.http.post(url, options) as Observable<any>;
    }

    public restartProcess(controllerId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}network-controllers/${controllerId}/process/restart`;
        return this.http.post(url, options) as Observable<any>;
    }

    public resize(controllerId: string, size: string): Observable<string> {
        const body: any = { size };
        const url = `${this.apiUrl}network-controllers/${controllerId}/host/resize`;
        return this.http.post(url, body) as Observable<any>;
    }

    public suspend(controllerId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}network-controllers/${controllerId}/suspend`;
        return this.http.post(url, options) as Observable<any>;
    }

    public resume(controllerId: string): Observable<string> {
        const options: PagedGetOption = {};
        const url = `${this.apiUrl}network-controllers/${controllerId}/resume`;
        return this.http.post(url, options) as Observable<any>;
    }

    public restoreBackup(controllerId: string, backupFile: string): Observable<string> {
        const options: any = {};
        const body: any = { backupFile };
        const url = `${this.apiUrl}network-controllers/${controllerId}/restore`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public listBackups(controllerId: string): Observable<any> {
        const options: any = {};
        const url = `${this.apiUrl}network-controllers/${controllerId}/backups`;
        return this.http.get(url, options) as Observable<any>;
    }

    public createBackup(controllerId: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}network-controllers/${controllerId}/backups`;
        return this.http.post(url, options) as Observable<any>;
    }

    public move(id: string, provider: string, region: string, ipAddress: string): Observable<string> {
        const options: any = {};
        const body: any = { provider, region, ipAddress };
        const url = `${this.apiUrl}network-controllers/${id}/move`;
        return this.http.post(url, body, options) as Observable<any>;
    }

    public validateMove(controllerId: string, provider: string, region: string, ipAddress: string) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        const body: any = { provider, region, ipAddress };
        return this.http
            .post(this.apiUrl + 'network-controllers/' + controllerId + '/move', body, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }

    public getJson(id: string): Observable<string> {
        const options: any = {};
        const url = `${this.apiUrl}network-controllers/${id}`;
        return this.http.get(url, options) as Observable<any>;
    }

    public downloadToCsv(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('size', this.lastTotalCount);
        const headers = new HttpHeaders().set('Accept', 'text/csv');
        return this.http.get(this.apiUrl + 'network-controllers', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }

    public getJWT(networkId: string): Observable<any> {
        const params = new HttpParams().set('networkId', networkId).set('beta', 'true');
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(this.apiUrl + 'network-controllers', {
            headers: headers,
            params: params,
        });
    }

    public downloadFileFormat(
        networkId: string,
        format = 'text/csv',
        params: HttpParams = new HttpParams()
    ): Observable<any> {
        params = params.set('networkId', networkId);
        const headers = new HttpHeaders().set('Accept', format);
        return this.http.get(this.apiUrl + 'network-controllers', {
            headers: headers,
            params: params,
            responseType: 'text',
        });
    }
}
